<template>
  <v-card>
    <v-card-title>
      Projects
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
      <v-btn @click="$router.push({ name: 'MobileProjectCreate' })" color="primary">Create Project</v-btn>
    </v-card-title>

      <v-data-table :headers="headers" :items="projects" item-key="name" :search="search">
        <template v-slot:[`item.status`]="{ item }">
          <label v-if="item.status == 'A'">Active</label>
          <label v-if="item.status == 'I'">Inactive</label>
          <label v-if="item.status == 'C'">Closed</label>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span>{{ $languageProcessor.getEnText(item.name) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <a class="mr-5" :href="'/mobile/project/' + item._id + '/' +  'edit'" target="_blank">View</a>
          <a :href="'/mobile/project/' + item._id + '/' +  'status'" target="_blank">Check Status</a>
        </template>
      </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();
export default {
  data() {
    return {
      search: "",
      projects: [],
      headers: [
        { text: "Id", value: "_id", sortable: false },
        { text: "Project Name", value: "name", sortable: false },
        { text: "Status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },

  mounted() {
    this.$setLoader();
    this.loadProjects();
  },

  methods: {
    loadProjects() {
      service.fetchProjects().then(r => {
        this.projects = r.data;
        this.$disableLoader();
      });
    }
  }
};
</script>

<style scoped>
#bt1 {
  float: right;
}
</style>
